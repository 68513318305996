
<template>
  <v-app>
    <!-- 1°first ROW -->
    <div class="row">
      <div class="col-md-12">
        <!-- 1°first Card -->
        <v-card>
          <v-card-title>
            Tipo de Movimiento
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on}">
                <v-btn v-on="on" class @click="reinitialize" text icon color="blue">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Actualizar Tabla</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class @click="exportExcel" text icon color="green">
                  <v-icon>fas fa-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar Excel</span>
            </v-tooltip>
          </v-card-title>
          <div v-if="loadTransition"  class="alert bluesky-line m-0 mb-0" ></div>
          <div class="card-body">
            <v-data-table
              :headers="headers"
              :items="reasons"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :search="search"
              sort-by="id"
              class="elevation-1 datatable"
            >
              <template v-slot:top>
                <v-card class="mt-1">
                <v-spacer></v-spacer>
                <v-toolbar flat color="white">
                  <div class="kt-input-icon kt-input-icon--left">
                    <input
                      v-model="search"
                      append-icon="search"
                      label="Búsqueda"
                      type="text"
                      class="form-control form-control-md col-md-8"
                      placeholder="Buscar..."
                    />
                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                  <!--<v-text-field
                    class="text-xs-center"
                    v-model="search"
                    append-icon="search"
                    label="Búsqueda"
                  ></v-text-field>-->
                  
                  <v-spacer></v-spacer>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark class="mb-2 btn-starkoms-primary" v-bind="attrs" v-on="on">
                        <i class="fa fa-plus mr-2"></i> Tipo de Movimiento
                      </v-btn>
                    </template>
                    <!-- 2°second Card -->
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-form ref="form" v-model="valid" lazy-validation>
                            <!-- 2°second ROW -->
                            <v-row>
                              <v-col cols="12" sm="6" md="2" v-if="editedItem.id">
                                <v-text-field
                                  v-model="editedItem.id"
                                  name="id"
                                  label="ID"
                                  readonly="readonly"
                                  prepend-icon="vpn_key"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="editedItem.name"
                                  :rules="[v => !!v || 'Ingresar un tipo de movimiento, es requerido!']"
                                  label="Tipo de movimiento"
                                  prepend-icon="edit_attributes"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#FF6347" text @click="close">Cancelar</v-btn>
                        <v-btn color="#00FF00" text @click="save">Guardar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                </v-card>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on}">
                    <router-link :to="`/inventario/reasons/${item.id}`">
                      <v-icon v-on="on" color="#93a2dd" small class="mr-2">mdi-eye</v-icon>
                    </router-link>
                  </template>
                  <span>Ver Tipo de Movimiento {{item.id}}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on}">
                    <v-icon small v-on="on" color="#93a2dd" class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                  </template>
                  <span>Editar Tipo de Movimiento {{item.id}}</span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <v-btn color="#87CEFA" :loading="loading" @click="reinitialize()"> Cargando ..</v-btn>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount" :total-visible="10"></v-pagination>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: {
    store_id: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    dialog: false,
    valid: true,
    loading: false,
    url: "",
    boolean: false,
    loadTransition: false,
    search: "",
    dateMsg:
      new Date().toISOString().substr(0, 10) +
      " " +
      new Date().toISOString().substr(11, 5),
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Nombre", value: "name" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    reasons: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
    },
    defaultItem: {
      id: "",
      name: "",
    },
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tipo de Movimiento", route: "/inventario/reasons" },
    ]);
    this.loading = true;
    this.fetchReasons();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Tipo de Movimiento"
        : "Editar Tipo de Movimiento";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //get the store alert deleted reason from reasonDetail component
      if (localStorage.getItem("deleteReaseon") !== null) {
        this.deletedReasonMessage();
      }
    },

    editItem(item) {
      this.editedIndex = this.reasons.indexOf(item);
      this.editedItem = Object.assign({}, item);

      //  console.log(reason);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      var vm = this;

      if (this.editedIndex > -1) {
        //Object.assign(this.reasons[this.editedIndex], this.editedItem);
       // let reason = Object.assign({}, this.editedItem);
        let editedReason = Object.assign({}, this.editedItem);

        if (!vm.editedItem.name.length || vm.editedItem.name == null) {
          vm.$bvToast.toast("El tipo de movimiento, no debe ir vacío!", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[1].focus();
          return;
        }

        if (this.$refs.form.validate()) {

          vm.loadTransition = true;

          this.axios({
            url: "inventory/reasons/" + vm.editedItem.id,
            method: "PUT",
            data: {
              name: vm.editedItem.name,
            },
          })
            .then((response) => {
              vm.fetchReasons();

              vm.$bvToast.toast(
                `El tipo de movimiento: ${editedReason.name}, ha sido Actualizado el ${vm.dateMsg} exitosamente!`,
                {
                  title: `Información`,
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-bottom-center",
                }
              );

              vm.loadTransition = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        // console.log(Object.assign(this.editItem));
        //this.reasons.push(this.editedItem);
        let createReason = Object.assign({}, this.editedItem);

        if (!vm.editedItem.name.length || vm.editedItem.name == null) {
          vm.$bvToast.toast("El tipo de movimiento, no debe ir vacío!", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[0].focus();
          return;
        }
        if (this.$refs.form.validate()) {

          vm.loadTransition = true;

          this.axios({
            url: "inventory/reasons",
            method: "POST",
            data: {
              name: vm.editedItem.name,
            },
          })
            .then((response) => {
              vm.fetchReasons();
              vm.$bvToast.toast(
                `El tipo de movimiento: ${createReason.name}, ha sido creada exitosamente!`,
                {
                  title: `Información`,
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-bottom-center",
                }
              );

              vm.loadTransition = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      this.close();
    },
    fetchReasons() {
      var vm = this;
      this.axios({
        url: "inventory/reasons",
        method: "GET",
      })
        .then((response) => {
          this.loading = false;
          vm.pageCount = Math.ceil(response.data.data.length / 10);
          vm.reasons = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletedReasonMessage() {
      let vm = this;
      let deletedReason = JSON.parse(localStorage.getItem("deleteReaseon"));

      this.$bvToast.toast(
        `El tipo de movimiento: ${deletedReason.name}, ha sido eliminada, el ${vm.dateMsg} exitosamente!`,
        {
          title: `Información`,
          variant: "success",
          solid: true,
          toaster: "b-toaster-bottom-center",
        }
      );
      localStorage.removeItem("deleteReaseon");
    },
    reinitialize() {
      this.reasons = [];
      this.loading = true;
      this.fetchReasons();
    },
    exportExcel() {
      var vm = this;
      let headers = vm.headers.map((e) => e.text);
      let datos = vm.reasons;
      this.axios({
        url: "excel/from_array",
        method: "POST",
        data: {
          data: datos,
          cabeceras: headers,
        },
        responseType: "blob",
      })
        .then((response) => {
          const newBlob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const data = window.URL.createObjectURL(newBlob);
          window.open(data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>